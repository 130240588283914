import React, { useEffect, useState, useRef } from 'react';
import OpenAI from 'openai';
import axios from 'axios';

const apiKey = "sk-proj-lPfrQ9unMgFv9rO7Io22T3BlbkFJfALcbcn7x0FAd89NBXEP";

if (!apiKey) {
    console.error('API key is missing! Please set REACT_APP_OPENAI_API_KEY in your .env file.');
}

const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

const Voice = () => {
    const [audioSrc, setAudioSrc] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [transcription, setTranscription] = useState("");
    const [responseText, setResponseText] = useState("");
    const [recognitionInstance, setRecognitionInstance] = useState(null);
    const recognitionRef = useRef(null);
    const audioRef = useRef(null);
    const isAudioPlayingRef = useRef(false);

    useEffect(() => {
        if (isRecording && !isAudioPlayingRef.current) {
            startRecognition();
        } else if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
    }, [isRecording]);

    const startRecognition = () => {
        const recognition = new window.webkitSpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        recognition.onstart = () => {
            setIsRecording(true);
        };

        recognition.onresult = async (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('');
            setTranscription(transcript);

            // Send text immediately after transcription
            try {
                const response = await axios.post('http://localhost:8000/api/process_text/', { text: transcript });
                setResponseText(response.data.response);
                generateSpeech(response.data.response); // Generate speech from the response text
            } catch (error) {
                console.error('Error sending text to the backend:', error);
            }
        };

        recognition.onerror = (event) => {
            console.error("Error during recognition:", event);
            setIsRecording(false);
        };

        recognition.onend = () => {
            if (isRecording && !isAudioPlayingRef.current) {
                recognition.start();
            }
        };

        recognition.start();
        recognitionRef.current = recognition;
        setRecognitionInstance(recognition); // Save the instance
    };

    const handleStopRecording = () => {
        setIsRecording(false);
    };

    const generateSpeech = async (text) => {
        try {
            const response = await openai.audio.speech.create({
                model: 'tts-1',
                voice: 'alloy',
                input: text,
            });

            const arrayBuffer = await response.arrayBuffer();
            const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
            const url = URL.createObjectURL(blob);

            setAudioSrc(url);
        } catch (error) {
            console.error('Error generating speech:', error);
        }
    };

    const handleAudioPlay = () => {
        isAudioPlayingRef.current = true;
        if (recognitionRef.current) {
            recognitionRef.current.stop();
        }
    };

    const handleAudioEnd = () => {
        isAudioPlayingRef.current = false;
        if (recognitionInstance) {
            recognitionInstance.start(); // Restart recognition
        }
    };

    return (
        <div>
            <button onClick={() => setIsRecording(!isRecording)} disabled={isRecording || isAudioPlayingRef.current}>
                {isRecording ? "Recording..." : "Start Recording"}
            </button>
            <button onClick={handleStopRecording} disabled={!isRecording}>
                Stop Recording
            </button>
            {transcription && (
                <div>
                    <h3>Transcription:</h3>
                    <p>{transcription}</p>
                </div>
            )}
            {responseText && (
                <div>
                    {/*<h3>Response Text:</h3>*/}
                    {/*<p>{responseText}</p>*/}
                </div>
            )}
            {audioSrc && (
                <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                    <audio
                        className="control"
                        ref={audioRef}
                        src={audioSrc}
                        controls
                        autoPlay
                        onPlay={handleAudioPlay}
                        onEnded={handleAudioEnd}
                    />
                    {/*{isAudioPlayingRef.current ? (*/}
                        <div className="voice">
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                            <div className="wave"></div>
                        </div>
                    {/*) : null}*/}
                </div>
            )}
        </div>
    );
};

export default Voice;
