import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ElectionHeader from './ElectionHeader';

const Elections = () => {
  const [elections, setElections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const responseDataString = sessionStorage.getItem("responseData");
  const responseData = responseDataString ? JSON.parse(responseDataString) : null;


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching data
      try {
        const response = await axios.get('https://rush-warship.onrender.com/api/elections/',{
          headers: {
            Authorization: `Token ${responseData.token}`,
          },
          maxBodyLength: Infinity,
        });
        setElections(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching elections:', error);
        setLoading(false);
        setError('Failed to fetch elections. Please try again later.');
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <ElectionHeader/>

      <section className="service_section layout_padding text-center">
        <div className="service_container">
          <div className="container">
            <div className="heading_container heading_center">
              <h2>
                Available <spant>Elections</spant>
              </h2>
            </div>
      
            <div className="row d-flex justify-content-center">
            {loading && (
              <div className='row'>
                <h4> Loading available elections ...</h4>
                <div className="loader"></div> 
              </div>
              
        
      )}
              {elections.map((election) => (
                <Link to={`/poll/`} className="col-md-10 box" key={election.id}>
                  <div className="">
                    <div className="detail-box">
                      <h5>{election.title}</h5>
                      {/* <h5>{election.id}</h5> */}
                      <h6 className='text-dark'>{election.description} <span style={{color: 'red'}}>Click here to start voting</span></h6>
                    </div>
                  </div>
                  
                </Link>
              ))}
            </div>
            <div className="btn-box">
              {/* <a href="#">View All</a> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Elections;
