// import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Elections from './components/Election';
import Auth from './components/Auth';
import Polling from './components/Polling';
import Result from './components/Result';
import Voice from './components/Voice'





function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home } />
        <Route path='/election' Component={Elections}/>
        <Route path='/auth' Component={Auth}/>
        <Route path='/poll/' Component={Polling}/>
        <Route path='/result/' Component={Result}/>
        <Route path='/voice' Component={Voice}/>

        {/* Add more routes as needed */}
      </Routes>
    </Router>

  );
}

ReactDOM.render(<App />, document.getElementById('root'));

export default App;
