
import { React, useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import { Link } from "react-router-dom";

export default function Home() {

  const apiUrl = "https://code-vote-ax01.onrender.com";

  function fetchData() {
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        // Handle the data as needed
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors
      });
  }
  
  // Call fetchData every 10 seconds
  // setInterval(fetchData, 180000);


  return (
    <div>
         <div className="hero_area">
      <div className="hero_bg_box">
        <div className="bg_img_box">
          <img src="images/hero-bg.png" alt="" />
        </div>
      </div>

<Header/>

      {/* Slider Section */}
      <section className="slider_section">
        <div id="customCarousel1" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="detail-box container-fluid" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                      <div className="text-light landing-text">
                   CoDE WELFARE ONLINE ELECTIONS
                        
                      </div>
                      <p>
                        {/* Explicabo esse amet tempora quibusdam laudantium, laborum eaque magnam fugiat hic? Esse dicta aliquid
                        error repudiandae earum suscipit fugiat molestias, veniam, vel architecto veritatis delectus repellat
                        modi impedit sequi. */}
                      </p>
                      <div className="btn-box">
                        <Link to="/auth" className="btn1 p-3 rounded m-5">
                          GET STARTED
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="img-box">
                      <img src="images/img/05.jpg" alt="" style={{borderRadius: '100%'}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Add other carousel items as needed */}
          </div>
          <ol className="carousel-indicators">
            <li data-target="#customCarousel1" data-slide-to="0" className="active"></li>
            {/* Add other indicators as needed */}
          </ol>
        </div>
      </section>
      {/* End Slider Section */}
    </div>
    </div>
  );
}
