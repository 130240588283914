import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Polling.css";
import Header from "./Header";

const Result = () => {
  const { electionId } = useParams(); // Extract electionId from URL parameters
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentAffiliationIndex, setCurrentAffiliationIndex] = useState(0);
  const responseDataString = sessionStorage.getItem("responseData");
  const responseData = responseDataString ? JSON.parse(responseDataString) : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://rush-warship.onrender.com/api/count-ballots/?election=${electionId}`,
          {
            headers: {
              Authorization: `Token ${responseData.token}`,
            },
            maxBodyLength: Infinity,
          }
        );
        setCandidates(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching candidates:", error);
        setError("Failed to fetch candidates. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [electionId]);

  // Group candidates by affiliation
  const groupedCandidates = candidates.reduce((acc, candidate) => {
    const affiliationName = candidate.affiliation.name;
    if (!acc[affiliationName]) {
      acc[affiliationName] = [];
    }
    acc[affiliationName].push(candidate);
    return acc;
  }, {});

  const affiliations = Object.keys(groupedCandidates).reverse();
  const currentAffiliation = affiliations[currentAffiliationIndex];

  const handleNextAffiliation = () => {
    setCurrentAffiliationIndex(
      (prevIndex) => (prevIndex + 1) % affiliations.length
    );
  };

  const handlePreviousAffiliation = () => {
    setCurrentAffiliationIndex(
      (prevIndex) => (prevIndex - 1 + affiliations.length) % affiliations.length
    );
  };

  if (loading) {
    return (
      <section>
        <div
          className="row d-flex"
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10em",
          }}
        >
          <h4 className="text-primary">Loading...</h4>
          <br />
          <div className="loader"></div>
        </div>
      </section>
    );
  }

  return (
    <div> 
      <Header />
      <section className="team_section layout_padding">
        <div className="container-fluid">
          <div className="heading_container py-2 heading_center bg-light">
            <div className="text-primary col-12">
              <div className="btn btn-outline-success col-lg-4 col-sm-12" style={{fontSize: "23px"}}>
                {currentAffiliation}
              </div>
              {groupedCandidates[currentAffiliation] && (
                <div className="btn btn-outline-success col-lg-4 col-sm-12" style={{fontSize: "23px"}}>
                  Total votes: {groupedCandidates[currentAffiliation][0]?.total_votes_by_affiliation}
                </div>
              )}
            </div>
            {error && <div className="alert">{error}</div>}
          </div>

          {currentAffiliation && (
            <div>
              <div className="team_container">
                <div
                  className="row container-fluid justify-content-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {groupedCandidates[currentAffiliation].map((candidate) => (
                    <div
                      className="col-lg-5 container-fluid m-lg-2 col-sm-12 rounded"
                      key={candidate.id}
                    >
                      <div className="box row">
                        <div className="col-12 col-lg-5">
                          <div className="img-box">
                            <img
                              src={candidate.image}
                              className="img1"
                              alt={candidate.name}
                            />
                          </div>
                        </div>

                        <div className="detail-box social_box rounded col-12 col-lg-7">
                          <div
                            className="btn my-3 btn-outline-primary"
                            style={{ width: "100%", fontSize: "17px" }}
                          >
                            {groupedCandidates[currentAffiliation].length === 1 ? (
                              <div>
                                No: {candidate.total_no_votes} (
                                {candidate.total_votes_by_affiliation > 0
                                  ? (
                                      (candidate.total_no_votes /
                                        candidate.total_votes_by_affiliation) *
                                      100
                                    ).toFixed(1)
                                  : 0}
                                %)
                                -
                                Yes: {candidate.total_yes_votes} (
                                {candidate.total_votes_by_affiliation > 0
                                  ? (
                                      (candidate.total_yes_votes /
                                        candidate.total_votes_by_affiliation) *
                                      100
                                    ).toFixed(1)
                                  : 0}
                                %)
                                <br />
                              </div>
                            ) : (
                              <div>
                                Votes: {candidate.total_yes_votes} (
                                {candidate.total_votes_by_affiliation > 0
                                  ? (
                                      (candidate.total_yes_votes /
                                        candidate.total_votes_by_affiliation) *
                                      100
                                    ).toFixed(1)
                                  : 0}
                                %)
                                <br />
                              </div>
                            )}
                          </div>
                          <div className="btn my-3 btn-primary"
                          style={{ width: "100%", fontSize: "25px" }}>
                            {candidate.name}
                          </div>
                          <div className="btn my-3 btn-outline-primary"
                          style={{ width: "100%", fontSize: "25px" }}>
                            #N/A
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-center mt-4">
                <div
                  onClick={handlePreviousAffiliation}
                  className="btn btn-secondary mx-2"
                  disabled={loading || currentAffiliationIndex <= 0}
                >
                  Previous
                </div>
                <div
                  onClick={handleNextAffiliation}
                  className="btn btn-primary mx-2"
                  disabled={
                    loading ||
                    currentAffiliationIndex >= affiliations.length - 1
                  }
                >
                  Next
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Result;
