import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ElectionHeader = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [sidenavWidth, setSidenavWidth] = useState(isSmallScreen ? 0 : 250);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth <= 768;
      setIsSmallScreen(smallScreen);
      if (!smallScreen) {
        setSidenavWidth(250);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on mount

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openNav = () => {
    if (isSmallScreen) {
      setSidenavWidth(250);
    }
  };

  const closeNav = () => {
    if (isSmallScreen) {
      setSidenavWidth(0);
    }
  };

  return (
    <div>
      <div id="mySidenav" className="sidenav" style={{ width: `${sidenavWidth}px` }}>
        <Link href="" className="closeBtn" onClick={closeNav}>&times;</Link>
        <Link to="/"><i className='mdi mdi-home m-3'></i>Home</Link>
        <Link to="/election"><i className='mdi mdi-format-list-checkbox m-3'></i>Elections</Link>
        <Link to="/poll"><i className='mdi mdi-vote-outline m-3'></i>Cast Vote</Link>
        <Link to="#"><i className='mdi mdi-archive-eye-outline m-3'></i>View Result</Link>
      </div>
      <div className="topBar" style={{display: "flex", alignItems: "center"}}>
        {/* <span className='openBtn mx-5' style={{ fontSize: '20px', cursor: 'pointer' }} onClick={openNav}>&#9776;</span> */}
        <h4 className="mx-5 btn btn-outline-primary">CoDE WELFARE ELECTIONS</h4>
        <div className="dropdown">
          {/* <em className="mdi mdi-36px mdi-account-outline mx-lg-5 dropbtn"></em> */}
          {/* <div class="dropdown-content">
            <a href="#">Link khsdfhoiufuerto8ret76ewtrw78ei57 61</a>
            <a href="#">Link 2</a>
            <a href="#">Link 3</a>
          </div> */}
          <Link to="/">Log out</Link>
        </div>
      </div>
    </div>
  );
};

export default ElectionHeader;
