import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Auth() {
  const [studentId, setStudentId] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State for loader visibility
  const navigate = useNavigate();

  const handleStudentIdChange = (event) => {
    setStudentId(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loader when form is submitted

    const data = new FormData();
    data.append('voter_unique_id', studentId);
    data.append('password', password);

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://rush-warship.onrender.com/api/token/',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));

      // Store response data in session storage
      sessionStorage.setItem('responseData', JSON.stringify(response.data));

      // Redirect to Elections page after successful login
      navigate('/election');
    } catch (error) {
      console.log(error);
      window.alert(error.response.data.non_field_errors)
    }

    // Reset the form fields and hide loader
    setLoading(false);
    setStudentId('');
    setPassword('');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="container col-lg-4 col-md-6">
        <form onSubmit={handleSubmit}>
          <div style={{ padding: '16px', backgroundColor: 'white', borderRadius: '8px' }}>
            <h1 style={{ textAlign: 'center' }}>Log in</h1>
            <p style={{ textAlign: 'center' }}>Please fill in this form to login.</p>

            <label htmlFor="studentId"><b>Staff ID</b></label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your staff ID"
              name="studentId"
              id="studentId"
              value={studentId}
              onChange={handleStudentIdChange}
              required
              style={{ width: '100%', padding: '15px', margin: '5px 0 22px 0', border: 'none', background: '#f1f1f1' }}
            />

            <label htmlFor="password"><b>Password</b></label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter Password"
              name="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
              style={{ width: '100%', padding: '15px', margin: '5px 0 22px 0', border: 'none', background: '#f1f1f1' }}
            />

            <button type="submit" className="registerbtn" style={{ backgroundColor: 'rgb(0,0,200)', color: 'white', padding: '16px 20px', margin: '8px 0', border: 'none', cursor: 'pointer', width: '100%', borderRadius: '4px', opacity: '0.9', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {loading && <div className="loader"></div>} {/* Show loader if loading */}
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
