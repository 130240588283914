import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./Polling.css";
import Header from "./Header";

const Polling = () => {
  const { electionId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [votedCandidates, setVotedCandidates] = useState(() => {
    const savedVotes = sessionStorage.getItem("votedCandidates");
    return savedVotes ? JSON.parse(savedVotes) : {};
  });
  const responseDataString = sessionStorage.getItem("responseData");
  const responseData = responseDataString ? JSON.parse(responseDataString) : null;
  const [currentAffiliationIndex, setCurrentAffiliationIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://rush-warship.onrender.com/api/count-ballots/?election=${electionId}`,
          {
            headers: {
              Authorization: `Token ${responseData.token}`,
            },
            maxBodyLength: Infinity,
          }
        );
        setCandidates(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching candidates:", error);
        setError("Failed to fetch candidates. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [electionId, responseData]);

  const postBallot = async (candidateId, vote) => {
    try {
      setLoading(true);

      const data = new FormData();
      data.append("voter", "9");
      data.append("election_id", electionId);
      data.append("candidate", candidateId);
      data.append("voter_unique_id", "0002");
      data.append("vote", vote);

      const response = await axios.post(
        "https://rush-warship.onrender.com/api/ballots/",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${responseData.token}`,
          },
          maxBodyLength: Infinity,
        }
      );

      console.log("Response:", response.data);
      window.alert("Vote casted successfully");
      const updatedVotedCandidates = {
        ...votedCandidates,
        [candidateId]: vote,
      };
      setVotedCandidates(updatedVotedCandidates);
      sessionStorage.setItem(
        "votedCandidates",
        JSON.stringify(updatedVotedCandidates)
      );
    } catch (error) {
      console.error("Error:", error.response.data[0]);
      window.alert(error.response.data[0]);
      setError(error.response?.data || error.response.data[0]);
    } finally {
      setLoading(false);
    }
  };

  const hasVotedForAffiliation = (affiliation) => {
    return candidates.some(
      (candidate) =>
        candidate.affiliation.name === affiliation &&
        votedCandidates[candidate.id]
    );
  };

  const groupedCandidates = (candidates) => {
    return Array.isArray(candidates)
      ? candidates.reduce((acc, candidate) => {
          const affiliationName = candidate.affiliation.name;
          if (!acc[affiliationName]) {
            acc[affiliationName] = [];
          }
          acc[affiliationName].push(candidate);
          return acc;
        }, {})
      : {};
  };

  const affiliations = Object.keys(groupedCandidates(candidates));
  const currentAffiliation = affiliations[currentAffiliationIndex];

  const handleNextAffiliation = () => {
    setCurrentAffiliationIndex(
      (prevIndex) => (prevIndex + 1) % affiliations.length
    );
  };

  const handlePreviousAffiliation = () => {
    setCurrentAffiliationIndex(
      (prevIndex) => (prevIndex - 1 + affiliations.length) % affiliations.length
    );
  };

  const hasOneCandidateInAffiliation = (affiliation) => {
    const candidatesInAffiliation = candidates.filter(
      (candidate) => candidate.affiliation.name === affiliation
    );
    return candidatesInAffiliation.length === 1;
  };

  return (
    <div>
      <Header />
      <section className="team_section layout_padding">
        <div className="container-fluid">
      
          {currentAffiliation && (
            <div>
              <div className="heading_container py-2 heading_center bg-light">
                <div className="text-primary">
                  <button className="btn btn-outline-success" style={{fontSize: "25px"}}>
                    {currentAffiliation}
                  </button>
                </div>
              </div>
              <div className="team_container">
                <div className="row container-fluid justify-content-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {groupedCandidates(candidates)[currentAffiliation].map((candidate) => (
                    <div className="col-lg-5 container-fluid m-lg-2 col-sm-12 rounded" key={candidate.id}>
                      <div className="box row">
                        <div className="col-12 col-lg-6">
                          <div className="img-box">
                            <img
                              src={candidate.image}
                              className="img1"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="detail-box">
                            <h4 style={{ color: "white" }}>
                              {candidate.name.toUpperCase()}
                            </h4>
                          </div>
                          <div className="detail-box">
                            <h3 style={{ color: "white" }}>
                              #N/A
                            </h3>
                          </div>
                          <div className="social_box rounded">
                            {hasOneCandidateInAffiliation(currentAffiliation) ? (
                              <React.Fragment>
                                <button
                                  onClick={() => postBallot(candidate.id, "yes")}
                                  className={`btn my-3 ${votedCandidates[candidate.id] === "yes" ? "btn-success" : "btn-outline-success"} container-fluid`}
                                  disabled={loading || hasVotedForAffiliation(currentAffiliation)}
                                  style={{ width: "100%" }}
                                >
                                  {hasVotedForAffiliation(currentAffiliation) ? "VOTE CASTED" : "VOTE YES"}
                                </button>
                                <button
                                  onClick={() => postBallot(candidate.id, "no")}
                                  className={`btn ${votedCandidates[candidate.id] === "no" ? "btn-danger" : "btn-outline-danger"} container-fluid`}
                                  disabled={loading || hasVotedForAffiliation(currentAffiliation)}
                                >
                                  {hasVotedForAffiliation(currentAffiliation) ? "VOTE CASTED" : "VOTE NO"}
                                </button>
                              </React.Fragment>
                            ) : (
                              <button
                                onClick={() => postBallot(candidate.id, "yes")}
                                className={`btn ${votedCandidates[candidate.id] === "yes" ? "btn-success" : "btn-outline-success"} container-fluid`}
                                disabled={loading || hasVotedForAffiliation(currentAffiliation)}
                              >
                                {hasVotedForAffiliation(currentAffiliation) ? "VOTE CASTED" : "VOTE FOR"}
                              </button>
                            )}
                            {loading && (
                              <div className="loader-overlay">
                                <div className="loader"></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-center mt-4">
                <button
                  onClick={handlePreviousAffiliation}
                  className="btn btn-secondary mx-2"
                  disabled={loading || currentAffiliationIndex <= 0}
                >
                  Previous
                </button>
                <button
                  onClick={handleNextAffiliation}
                  className="btn btn-primary mx-2"
                  disabled={
                    loading ||
                    currentAffiliationIndex >= affiliations.length - 1
                  }
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
      <section>
        {loading && (
          <div
            className="row d-flex"
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10em",
            }}
          >
            <h4 className="text-primary">Loading...</h4>
            <br />
            <div className="loader"></div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Polling;
